import React, { useState, useEffect, useContext } from "react";
import './index.css';
import Header from '../../components/Header';
import { AuthContext } from "../../contexts/auth";
import moment from 'moment';

import { get_projects, get_flows, ponto_entrada, last_log, ponto_saida } from "../../services/api";

const Dashboard = () => {

    const { user, logout } = useContext(AuthContext)

    const [projects, setProjects] = useState([]);
    const [flows, setFlows] = useState([]);

    const [project, setProject] = useState(null);
    const [flow, setFlow] = useState(null);

    const [saida, setSaida] = useState(null);

    // button states
    const [btnentrada, setBtnentrada] = useState(true);
    const [btnsaida, setBtnsaida] = useState(true);

    // active classes
    const [active_project, setActive_project] = useState();
    const [active_flow, setActive_flow] = useState();

    const [time, setTime] = useState(new Date().toLocaleTimeString());

    const [message, setMessage] = useState('');

    setInterval(() => {
        setTime(new Date().toLocaleTimeString());
    }, 1000);

    useEffect(() => {
        // Verifica se o utilizador marcou o ponto de saida
        (async () => {
            const response = await last_log(user.id);
            const log = response.data;

            if (log.length > 0) {
                if (log[0].data_saida == null) {
                    setSaida(log[0].id)
                    setBtnsaida(false);
                    setMessage(`Mensagem: Está trabalhando no fluxo ${log[0].flow_name} da obra (${log[0].id_comum} - ${log[0].obra_name})`);
                    console.log('ainda nao marcou a saida')
                } else {
                    // Executa a rotina de listagem de flows 
                    const response = await get_flows();
                    setFlows(response.data);
                    console.log(`Utilizador nome ${user.name} tem saida marcada e ja pode marcar entrada`);
                }
            } else {
                // Executa a rotina de listagem de projectos 
                const response = await get_flows();
                setFlows(response.data);
                console.log(`Utilizador nome ${user.name} tem saida marcada e ja pode marcar entrada`);
            }
        })();

        // Seta um timeout para fazer o login em 25 segundos após o carregamento da página
        const session_time = setTimeout(() => {
            alert('Tempo de sessão expirado!')
            logout();
        }, 25000);

        // Caso a página seja desmontada (sair da página index) o timeout será limpo para não ser executado
        return () => window.clearTimeout(session_time)

        // UTILIZANDO ESSA LINHA COMENTADA PARA PODER UTILIZAR O LOGOUT DENTRO DO EFFECT SEM O WARNING (ELE FUNCIONA NORMAL MAS DÁ UM WARNING DE DEPENDENCIA DO EFFECT PELO MÉTODO LOGOUT SER CHAMADO DE OUTRO LADO)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id, user.name]);

    function handeProject(id) {
        setProject(id);
        setBtnentrada(false)
    }

    function handeFlow(id) {
        (async () => {
            const response = await get_projects(id);
            setProjects(response.data);
            setProject(null);
            setBtnentrada(true)
            setActive_project();
        })();
        setFlow(id);
    }

    async function handeEnter() {
        const date = new Date();
        const actual_date = moment(date).format('YYYY-MM-DD');
        const full = actual_date + ' ' + time;

        const dados = {
            user_id: user.id,
            job_id: project,
            flow_id: flow,
            entrada: full
        }

        if (dados.job_id === null || dados.flow_id === null) {
            alert('Por favor selecione um projecto e um fluxo');
        } else {
            console.log("enviar os dados para api")
            const response = await ponto_entrada(dados);
            console.log(response.data);
            alert("Ponto de entrada marcado com sucesso");
            logout();
        }
    }

    // Preciso termiar aqui essa funcao de saida
    async function handeExit() {
        const date = new Date();
        const actual_date = moment(date).format('YYYY-MM-DD');
        const full = actual_date + ' ' + time;

        const dados = {
            user_id: user.id,
            saida: full
        }

        const response = await ponto_saida(saida, dados);
        console.log(response.data);
        alert("Ponto de saída marcado com sucesso");
        logout();
    }

    // Preciso termiar aqui essa funcao de saida
    async function handeReenter() {
        const date = new Date();
        const actual_date = moment(date).format('YYYY-MM-DD');
        const full = actual_date + ' ' + time;

        const dados = {
            user_id: user.id,
            saida: full
        }

        const response_saida = await ponto_saida(saida, dados);
        console.log(response_saida)
        // Executa a rotina de listagem de flows 
        const response = await get_flows();
        setFlows(response.data);
        setBtnsaida(true);
        setMessage(`Mensagem: Saída marcada com sucesso e pronto para marcar entrada novamente!`);
        console.log(`Utilizador nome ${user.name} tem saida marcada e ja pode marcar entrada`);
    }

    return (
        <>
            <Header />
            <div className="container">
                <h1>Ponto WEB</h1>
                <div className="time-section">
                    <span className="time">{time}</span>
                </div>
                <div className="projects">
                    <div className="projects-section">
                        <h2>Flows</h2>
                        {flows.map(flow => (
                            <div key={flow.id}
                                className={active_flow === flow.id ? "project-item active_flow" : "project-item"}
                                onClick={() => {
                                    if (active_flow === flow.id) {
                                        setActive_flow();
                                    } else {
                                        setActive_flow(flow.id);
                                        handeFlow(flow.id)
                                    }
                                }}>
                                <p>{flow.name}</p>
                            </div>
                        ))}
                    </div>
                    <div className="projects-section">
                        <h2>Projectos</h2>
                        {projects.map(project => (
                            <div key={project.id}
                                className={active_project === project.id ? "project-item active_project" : "project-item"}
                                onClick={() => {
                                    // if (active_project === project.id) {
                                    //     setActive_project();
                                    // } else {
                                    setActive_project(project.id);
                                    handeProject(project.id)
                                    // }
                                }}>
                                <p>{project.id_comum} - {project.name}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <h3>{message}</h3>

                <div className="actions">
                    <button disabled={btnentrada} onClick={() => handeEnter()} className="entrada">Marcar Entrada</button>
                    <button disabled={btnsaida} onClick={() => handeReenter()} className="reentrada">Alterar projeto</button>
                    <button disabled={btnsaida} onClick={() => handeExit()} className="saida">Marcar Saida</button>
                </div>
            </div>
        </>
    )
}

export default Dashboard;