import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom'

import { FiLogIn } from 'react-icons/fi'

import './styles.css'

import logo from '../../assets/ponto_web.png'
import rocket from '../../assets/account.png'

import { AuthContext } from "../../contexts/auth";

const LoginPage = () => {

    const { login, error } = useContext(AuthContext);

    const [identifier, setIdentifier] = useState("");
    const [pin, setPin] = useState("");

    const [time, setTime] = useState(new Date().toLocaleTimeString());

    setInterval(() => {
        setTime(new Date().toLocaleTimeString());
    }, 1000);

    const handleSubmit = (e) => {
        e.preventDefault();
        login(identifier, pin)
    }

    return (
        <div className="logon-container">
            <section className="form">
                <img src={logo} id='logo' alt='logo' />

                <form onSubmit={handleSubmit}>
                    <h1>Faça seu login</h1>
                    <input
                        value={identifier}
                        onChange={(e) => setIdentifier(e.target.value)}
                        type="text"
                        name="identifier"
                        id="identifier"
                        placeholder="Identificador"
                        maxLength={12}
                    />
                    <input
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                        type="password"
                        name="pin"
                        id="pin"
                        placeholder="PIN"
                        maxLength={6}
                    />

                    {error && <p className="error">{error}</p>}

                    <button className="button" type="submit">Entrar</button>

                    <Link to="#">
                        <FiLogIn size={16} color="#FFF" />
                        Plataforma de Gestão de Ponto
                    </Link>
                </form>

            </section>

            <section className="time_now">
                <span className="time">{time}</span>
                <img src={rocket} id="rocket" alt='logo' />
            </section>
        </div>
    )
}

export default LoginPage;