import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from 'react-router-dom'
import { api, createSession } from '../services/api'

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate();

    useEffect(() => {
        const user_recovered = localStorage.getItem("user");
        if (user_recovered) {
            setUser(JSON.parse(user_recovered))
        }
        setLoading(false)
    }, []);

    const login = async (identifier, pin) => {

        if (!identifier || !pin) {
            alert("Preencha todos os campos")
        }

        const response = await createSession(identifier, pin);

        console.log(response.data)

        // se der erro na requisicao
        if (response.data.status === false) {
            setError(response.data.message)
        } else {
            const logged_user = {
                id: response.data.user.user_id,
                name: response.data.user.user_name,
                email: response.data.user.user_email,
                photo: response.data.user.photo
            };

            const token = response.data.token;
            api.defaults.headers.Authorization = 'Bearer ' + token;
            localStorage.setItem("user", JSON.stringify(logged_user))
            localStorage.setItem("token", token)
            setUser(logged_user)
            navigate("/dashboard")
        }
    }

    const logout = () => {
        console.log('logout')
        setUser(null);
        localStorage.removeItem("user")
        localStorage.removeItem("token")
        api.defaults.headers.Authorization = null;
        navigate("/")
    }

    return (
        <AuthContext.Provider value={{ authenticated: !!user, error, user, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    )
}