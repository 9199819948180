import React, { useContext } from "react";
import { Link } from 'react-router-dom'
import { FiLogOut } from 'react-icons/fi'
import logo from '../../assets/logo_spot.png';
import { AuthContext } from "../../contexts/auth"

import "./styles.css"

const Header = () => {

    const { user, logout } = useContext(AuthContext)

    const handleLogout = () => {
        logout();
    }

    if (user === null) {
        return (<div>Carregando...</div>);
    }

    return (
        <div className="profile-container">
            <header>
                <Link to="/">
                <img id="logo_header" src={logo} alt="logo" />
                </Link>
                <span>Bem vindo(a), {user.name}</span>
                <button onClick={handleLogout} type="button">
                    <FiLogOut size={18} color="#fff" />
                </button>
            </header>
        </div>
    )
}

export default Header;