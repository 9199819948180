import AppRoutes from './AppRoutes';
import './global.css';

function App() {
  return (
    <div className="app">
      <AppRoutes/>
    </div>
  );
}

export default App;
